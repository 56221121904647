<template>

<span>
    <v-layout mb-4>
      <v-flex xs10 ml-3>
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Tipos de Conteúdo - Edição</h2>
      </v-flex>
      <v-flex xs2 mr-3 text-right>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'contentTypes'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
    
    <form-content-type :contentType="contentType" :update="true"></form-content-type>
</span>
        
</template>

<script>
import FormContentTypeComponent from "./partial/FormContentTypeComponent";

export default {
  name: "EditContentTypeComponent",
  created() {
    this.loadContentType()
  },
  props: {
    cont_ty_id: {
      require: true
    }
  },
  data () {
    return {
      contentType: {
        cont_ty_id: '',
        cont_ty_nome: '',
        cont_ty_descricao: '',
        cont_ty_icone: '',
        cont_ty_imagem: '',
        cont_ty_status: false,
      },
    }
  },
  methods: {
    loadContentType() {
      this.$store.dispatch('loadContentType', this.cont_ty_id)
        .then(response => this.contentType = response)
        .catch(error => {
          this.$swal({
            position: "center",
            icon: "error",
            title: 'Erro',
            text: 'Dados não localizado',
            showConfirmButton: true,
            timer: 6000
          });
        })
    },
  },
  components: {
    formContentType: FormContentTypeComponent
  }
};
</script>

<style scoped>

</style>
